<template>
    <v-app-bar color="primary" flat fixed dark app>
        <v-spacer></v-spacer>
        <div class="d-flex align-center">
            <v-img class="drawer-header-logo" lazy-src="@/assets/img/wl-symbol-logo-white.png" width="50px" height="50px" src="@/assets/img/wl-symbol-logo-white.png" contain></v-img>
            <p class="ma-0 mx-3 text-h6 font-weight-bold">{{$t("storeTitle")}}</p>
        </div>
        <v-spacer></v-spacer>
        <v-btn  @click="drawer = true" size="32" icon>
            <v-icon size="30">mdi-account-circle</v-icon>
        </v-btn>
        <template v-slot:extension>
            <v-text-field readonly solo label="Trouver un article ou une offre" prepend-inner-icon="mdi-magnify" append-icon="mdi-barcode-scan" hide-details dense light></v-text-field>
        </template>
    </v-app-bar>
</template>
<script>
export default {
    data(){
        return {

        }
    }
}
</script>