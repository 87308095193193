<template>
    <div class="pb-8 grey lighten-5 full-height store" id="store">
        <StoreHeader />
        <p class="pt-3 px-6 font-weight-bold primary--text text-center text-body-1">{{$t("storeHomeTitle")}}</p>
        <div class="pa-3 pt-1 d-flex flex-column justify-center">
            <div class="store-body">
                <div class="pa-2 ma-2 white rounded-lg d-flex flex-column align-center store-item">
                    <v-img src="@/assets/img/drive.png" width="64" height="64"></v-img>
                    <p class="my-2 font-weight-bold primary--text text-center text-subtitle-2">{{$t("storeHomeDrive")}}</p>
                    <p class="text-center text-caption px-4">{{$t("storeHomeDriveText")}}</p>
                </div>
                <div class="pa-2 ma-2 white rounded-lg d-flex flex-column align-center store-item">
                    <v-img src="@/assets/img/delivery.png" width="64" height="64"></v-img>
                    <p class="my-2 font-weight-bold primary--text text-center text-subtitle-2">{{$t("storeHomeDelivery")}}</p>
                    <p class="text-center text-caption px-4">{{$t("storeHomeDeliveryText")}}</p>
                </div>
                <div class="pa-2 ma-2 white rounded-lg d-flex flex-column align-center store-item">
                    <v-icon color="green" size="64">mdi-list-box-outline</v-icon>
                    <p class="my-2 font-weight-bold text-center text-subtitle-2">{{$t("storeHomeProducts")}}</p>
                </div>
                <div class="pa-2 ma-2 white rounded-lg d-flex flex-column align-center store-item">
                    <v-icon color="red" size="64">mdi-sale-outline</v-icon>
                    <p class="my-2 font-weight-bold text-center text-subtitle-2">{{$t("storeHomeDiscount")}}</p>
                </div>
                <div class="pa-2 ma-2 white rounded-lg d-flex flex-column align-center store-item">
                    <v-icon color="blue" size="64">mdi-book-open-page-variant</v-icon>
                    <p class="my-2 font-weight-bold text-center text-subtitle-2">{{$t("storeHomeCatalog")}}</p>
                </div>
                <div class="pa-2 ma-2 white rounded-lg d-flex flex-column align-center store-item">
                    <v-icon color="blue" size="64">mdi-library-shelves</v-icon>
                    <p class="my-2 font-weight-bold text-center text-subtitle-2">{{$t("storeHomeSection")}}</p>
                </div>
                <div class="d-flex align-center pa-2 py-6 ma-2 white rounded-lg store-2-col-item" @click="handeCharge">
                    <v-icon color="light-green darken-1" size="64" class="mx-2" large>mdi-ev-station</v-icon>
                    <p v-if="startCharging" class="ma-0 ml-6 text-center font-weight-bold">{{$t("storeHomeCheckCharge")}}</p>
                    <p v-else class="ma-0 ml-6 text-center font-weight-bold">{{$t("storeHomeScanTerminal")}}</p>
                </div>
            </div>
            <div class="mt-2 advantages">
                <div class="d-flex justify-space-between">
                    <p class="text-subtitle-2 font-weight-bold">{{$t("storeHomeLoyalty")}}</p>
                    <p class="primary--text text-subtitle-2 font-weight-bold">{{$t("storeHomeSeeMore")}}</p>
                </div>
                <div class="pa-2 white rounded-lg d-flex justify-center align-baseline store-item">
                    <div class="mx-5 py-3 d-flex flex-column align-center">
                        <p class="mb-2 text-body-2 grey--text text--darken-1">{{$t("storeHomeLoyaltyBalance")}}</p>
                        <p class="pa-2 mb-2 rounded-pill font-weight-bold text-h5 primary--text teal lighten-5">24.49€</p>
                        <p class="mb-2 text-body-2 grey--text text--darken-1">30/08/2022</p>
                    </div>
                    <div class="mx-auto py-3 d-flex flex-column align-center">
                        <p class="mb-2 text-body-2 grey--text text--darken-1"></p>
                        <v-icon size="48" color="primary">mdi-wallet-giftcard</v-icon>
                        <p class="mb-2 text-body-2 grey--text text--darken-1"></p>
                    </div>
                    <div class="mx-5 py-3 d-flex flex-column align-center">
                        <p class="mb-2 text-body-2 grey--text text--darken-1">Coupons</p>
                        <p class="pa-2 mb-2 rounded-pill font-weight-bold text-h5 red--text amber accent-1">11</p>
                    </div>
                </div>
            </div>
        </div>

        <v-dialog v-model="showDiscountDialog">
            <v-card class="pa-3">
                <div class="d-flex">
                    <v-spacer></v-spacer>
                    <v-icon class="align-self-start" @click="handleCloseDiscountDialog">mdi-close</v-icon>
                </div>
                <div class="full-width d-flex align-center">
                    <v-spacer></v-spacer>
                    <v-icon class="my-2" size="64" color="light-green darken-1">mdi-ev-station</v-icon>
                    <v-spacer></v-spacer>
                </div>
                <v-card-text class="d-flex flex-column align-center">
                    <p class="mb-0 text-center font-weight-bold text-h6">{{$t("storeDiscountDialogText1")}}</p>
                    <p class="text-center font-weight-medium text-caption">{{$t("storeDiscountDialogText2")}}</p>
                    <v-btn color="primary" @click="handeCharge">{{$t("storeHomeScanTerminal")}}</v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showScan" fullscreen transition="dialog-bottom-transition" hide-overlay>
            <v-toolbar dark color="primary" >
                <v-btn icon dark @click="showScan = false" >
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-toolbar-title @click="onDecode('')">{{$t("storeScanDialogHeader")}}</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card class="d-flex flex-column justify-center black">
                <v-card-text class="pa-0 scanner-wrapper black">
                    <ScannerCamera></ScannerCamera>
                    <div class="scan-explain d-flex flex-column align-center">
                        <p class="pa-3 text-center explain-text white--text font-weight-bold text-h6">{{$t("storeScanDialogText")}}</p>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-bottom-navigation v-model="selectedTab" color="primary" flat fixed>
            <v-btn value="home">
                <span class="my-1">{{$t("storeHomeBottomNavHome")}}</span>
                <v-icon>mdi-home-outline</v-icon>
            </v-btn>

            <v-btn value="shelves">
                <span class="my-1">{{$t("storeHomeBottomNavSections")}}</span>
                <v-icon>mdi-library-shelves</v-icon>
            </v-btn>

            <v-btn value="products">
                <span class="my-1">{{$t("storeHomeBottomNavProducts")}}</span>
                <v-icon>mdi-list-box-outline</v-icon>
            </v-btn>

            <v-btn value="basket">
                <span class="my-1">{{$t("storeHomeBottomNavBasket")}}</span>
                <v-icon>mdi-cart-variant</v-icon>
            </v-btn>
        </v-bottom-navigation>
    </div>
</template>
<script>
const Barcode = require("@/assets/js/Barcode");
import StoreHeader from "@/components/StoreHeader.vue"
import ScannerCamera from "../components/ScannerCamera";

import { mapGetters, mapActions } from "vuex"
export default {
    name: 'Store',
    components: {ScannerCamera, StoreHeader},
    data(){
        return {
            page: {
                title: "storeTitle",
                description: "storeDescription"
            },
            selectedTab: "home",
            showScan: false,
            showDiscountDialog: false
        }
    },
    created () {
        let showDiscount = sessionStorage.getItem("IEC_PAY_AND_DRIVE_SHOW_DISCOUNT")
        if(showDiscount != null){
            this.showDiscountDialog = showDiscount == "true"
        }else{
            this.showDiscountDialog = true
        }
    },
    computed: {
        ...mapGetters({
            startCharging: "charge/startCharging",
        })
    },
    methods: {
        ...mapActions({
            updateShowChargingDialog: "charge/updateShowChargingDialog",
        }),
        showDialogScan(){
            Barcode.start(this.onDecode);
            this.showScan = true
        },
        hideDialogScan(){
            Barcode.reset()
            this.showScan = false
        },
        onDecode(decodedString) {
            console.log(decodedString)
            this.$router.push({name: 'EVCharging'})
        },
        handleCloseDiscountDialog(){
            sessionStorage.setItem("IEC_PAY_AND_DRIVE_SHOW_DISCOUNT", false)
            this.showDiscountDialog = false
        },
        handeCharge(){
            if(this.startCharging){
                this.updateShowChargingDialog(true)
            }else{
                this.showDialogScan()
                this.handleCloseDiscountDialog()
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        /* eslint-disable */
        Barcode.reset()
        next();
    },
}
</script>
<style>
.store-body{
    display: grid;
    grid-template-columns: repeat(2, 50%);
}

.store-2-col-item{
    grid-column: 1/-1;
}
.store, .scanner-wrapper{
    position: relative;
}
.scan-explain{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.explain-text{
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}
</style>
